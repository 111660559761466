@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --blue: #00bdee;
  --lightblue: #6be1fe;
  --yellow: #ffff30;
  --greyblue: rgb(0, 45, 56);
  --darkgrey: #0d1d22;
  --black: #0c0c0c;
  --white: #ffffff;
  --lightgrey: #f6f7f8;
  --midlightgrey: #f1f2f3;
  --midgrey: #e1e2e3;

  --gradient: linear-gradient(
    130deg,
    hsl(60deg 100% 57%) 0%,
    rgb(180, 255, 249) 50%,
    hsl(192deg 99% 71%) 100%
  );
}

* {
  margin: 0;
  list-style: none;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.section h1 {
  z-index: 1;
  font-size: 6rem;
  line-height: 5rem;
  color: var(--darkgrey);
  margin: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.section h1 em {
  all: unset;
  background: url(../public/calc-bg4.svg);
  background-position: 60% 50%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.hi-light {
  color: var(--yellow);
}

.section p {
  margin: 1.5rem;
}

.section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  text-align: start;
  padding-bottom: 200px;
}

.top-spacer {
  min-height: 12rem;
  width: 100%;
  background-color: var(--midlightgrey);
}

.title-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 500px;
  max-width: 1000px;
}
.title-container h1 {
  text-align: end;
}

#cake {
  width: 100%;
}

#logo {
  height: 6rem;
  margin: 1rem 0rem 1rem 1rem;
  grid-column-start: 1;
  grid-column-end: 2;
}

.bullet-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  max-width: 900px;
  margin: 2rem;
  gap: 2rem;
  overflow: visible;
}

.bullet-container.how div {
  position: relative;
  background-color: var(--greyblue);
  color: var(--lightgrey);
  font-size: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  padding: 2rem;
}

.bullet-container div {
  /* background-color: var(--lightblue); */
  color: var(--darkgrey);
  /* padding: 2rem; */
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.flex-direction-override {
  flex-direction: column;
}

.testimonial-img {
  border-radius: 25px;
  height: 50px;
  margin: 0rem;
  width: auto;
}

.quote-img {
  border-radius: 50px;
  height: 250px;
  margin: 2rem;
  width: auto;
  background-color: var(--blue);
}

.name-image {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.quote-container {
  display: flex;
  flex-direction: column;
}

.quote-container h5 {
  color: rgba(0, 0, 0, 0.8);
}

.testimonial-card {
  background-color: var(--midgrey);
  color: var(--darkgrey);
  min-width: 300px;
  max-width: 300px;
  height: 600px;
  border-radius: 3rem;
  box-sizing: border-box;
  padding: 3rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: -500px;
  animation: carousel 35s linear 0s;
}

.testimonial-card > p::before {
  content: open-quote;
  font-size: 5rem;
  color: var(--lightblue);
  position: absolute;
  top: 0rem;
  left: 0.5rem;
}
.testimonial-card > p::after {
  content: close-quote;
  font-size: 5rem;
  color: var(--lightblue);
  position: absolute;
  right: 0.5rem;
  translate: 0 -1.5rem;
}

.testimonial-card:hover {
  background: var(--blue);
  transition: background-color 1s;
}

#carousel {
  position: relative;
  width: 100dvw;
  width: 100vw;
  height: calc(600px + 2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  text-align: start;
  color: white;
  background-color: var(--lightgrey);
}

@keyframes carousel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2400px);
  }
}

.testimonial-card em {
  font-weight: 800;
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.dark {
  background-image: url(../public/dark-bg.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--midlightgrey);
  color: var(--darkgrey);
}

.light {
  background-image: url(../public/light-bg.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--darkgrey);
}

.large {
  min-height: 700px;
}

.medium {
  min-height: 400px;
}

.small {
  min-height: 250px;
  text-transform: none;
}

.CTA-button {
  all: unset;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50px;
  color: var(--darkgrey);
  height: fit-content;
  align-self: center;
  background: var(--gradient);
  background-size: 1000%;
  transition: background-position 1s, box-shadow 0.5s, color 0.5s;
}

.CTA-button:hover {
  box-shadow: 0 0px 20px -5px var(--lightblue);
  background-position: 60% 100%;
  color: var(--white);
  transition: background-position 1s, color 0.5s, box-shadow 0.5s;
}

.question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  animation: fadein 1s ease-in-out 0.3s both;
}

.question-input {
  display: flex;
  width: 100%;
  padding: 0rem;
}

.question-text {
  font-size: 0.9rem;
  width: 100%;
}
.question-wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
}

.question-options {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  padding-left: 0;
  animation: fadein 1s ease-in-out 0.6s both;
}

.question-option {
  display: flex;
  cursor: pointer;
  justify-content: center;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 50px;
  background: var(--gradient);
  background-size: 1000%;
  transition: background-position 1s, box-shadow 0.5s, color 0.5s;
  color: var(--darkgrey);
  font-weight: 600;
  height: fit-content;
  width: 150px;
}
.question-option:hover {
  box-shadow: 0 0px 20px -5px var(--lightblue);
  background-position: 60% 100%;
  color: var(--white);
  transition: background-position 1s, color 0.5s, box-shadow 0.5s;
}

.calculator-wrapper {
  display: flex;
  justify-content: space-between;
  background-image: url(../public/calc-bg4.svg);
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #111111;
  align-items: center;
  width: 100dvw;
  width: 100vw;
  min-width: 350px;
  height: 8rem;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 0.5s;
  color: var(--white);
  box-sizing: border-box;
}

#calculator-wrapper h1 {
  font-size: 1.2rem;
}

#calc-nav {
  opacity: 0%;
  display: flex;
  gap: 0.5rem;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: end;
}

.calc-container {
  position: relative;
  box-sizing: border-box;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 8rem auto auto auto;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  min-width: 320px;
}

.nav-button,
.min-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0px;
  width: 0px;
  padding: 1.1rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 300;
  transition: all 0.5s;
  backdrop-filter: blur(15px);
}

.max-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 0px;
  width: auto;
  padding: 1.1rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.5s;
  backdrop-filter: blur(5px);
}

.nav-button:hover,
.min-button:hover,
.max-button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

#weight-select-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

#weight {
  display: flex;
  flex-direction: column;
}

#weightType {
  margin: 1rem 1rem 0rem 1rem;
  border: unset;
  border-radius: 1rem;
  padding: 0.25rem;
}

#weightValue {
  margin: 0rem 1rem 0rem 1rem;
  border-radius: 1rem;
  border: none;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
}

#data-summary {
  display: flex;
  flex-direction: column;
}

#form-wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  animation: fadein 1s ease-in-out 0.3s both;
}
#form-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#form-wrapper form input {
  border-radius: 1rem;
  border: unset;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
}

#progress-bar {
  width: calc(100% - 4rem);
  margin: auto;
  height: 0.4rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  grid-column-start: 1;
  grid-column-end: 3;
  animation: fadein 1s ease-in-out 0.6s both;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  position: absolute;
  top: calc(100% - 2rem);
  translate: 0 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#progress {
  width: 0%;
  height: 20px;
  background: var(--gradient);
  background-size: 600%;
  transition: all 0.8s ease-in;
}
.nav-active {
  margin: 5rem;
  margin-top: calc(100dvh - 650px - 10rem);
  margin-top: calc(100vh - 650px - 10rem);
  width: 850px;
  height: 650px;
  border-radius: 50px;
  box-shadow: 0 30px 100px -20px rgb(50 50 93 / 25%),
    0 15px 60px -30px rgb(0 0 0 / 30%);
  transition: all 0.5s;
}

.minimised {
  height: 10rem;
  overflow: hidden;
  margin: 1rem;
  min-height: 0;
}

.stats {
  display: grid;
  justify-items: center;
  grid-template-columns: 33.33% 33.33% 33.33%;
  text-align: center;
}

.stats div {
  display: inline;
  width: 100%;
}

.stat {
  margin: 1rem;
}

.unit {
  font-size: 4rem;
  text-transform: none;
}

.fwd-bk {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.number {
  position: absolute;
  top: -3.5rem;
  left: -1.5rem;
  color: var(--blue);
  font-size: 5rem;
  font-weight: 900;
  font-style: italic;
  overflow: visible;
  z-index: 0;
}

.number-title {
  font-style: italic;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 2rem;
}

.result-container {
  padding: 1rem;
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  flex-direction: column;
}

.result-container h1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.q-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.q-desc {
  width: 150px;
  height: 150px;
  font-size: 0.7rem;
  padding: 1rem;
}

.data {
  display: inline-flex;
  font-weight: bold;
  font-size: large;
}

.test {
  display: flex;
  flex-direction: column;
  background-color: var(--black);
  padding: 2rem;
  border-radius: 25px;
}

/* mobile */

@media screen and (max-width: 850px) {
  .section h1 {
    font-size: 3rem;
    line-height: 2.5rem;
    margin: 1rem;
  }

  .title-container {
    display: flex;
    flex-direction: column;
  }

  .title-container h1 {
    text-align: center;
  }

  .unit {
    font-size: 2rem;
    text-transform: none;
  }

  .bullet-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }

  .bullet-container:after {
    content: "";
    display: table;
    clear: both;
  }

  .nav-active {
    margin: 1rem;
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    border-radius: 50px;
  }

  .minimised {
    height: 10rem;
  }

  .CTA-button {
    font-size: 0.7rem;
  }

  #logo {
    max-height: 5rem;
  }

  .stats {
    grid-template-columns: 1fr;
  }

  #calc-nav {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    padding-left: 0rem;
    gap: 0.5rem;
  }

  .min-button {
    justify-self: flex-end;
  }

  .question-options {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .q-cont .question-option {
    width: 100px;
  }

  .result-container p {
    font-size: 0.8rem;
  }

  .q-cont {
    flex-direction: row;
  }
  .q-desc {
    font-size: 10px;
    height: 100px;
    width: 200px;
  }

  .small {
    flex-direction: column;
  }
}

/* section animation */

.section > * {
  /* overflow: hidden; */
  transform: translateY(100px);
  opacity: 0;
}

.section > .active {
  transform: translateY(0px);
  opacity: 100;
  transition: transform 0.5s ease-out, opacity 0.5s ease-in;
}

/* question fade in animation */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#defaultCanvas0 {
  border-radius: 30px;
}
.react-p5 {
  place-self: center;
}

h1,
p,
div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#arrow {
  position: absolute;
  left: 0;
  z-index: 0;
  text-align: left;
  font-size: 10rem;
  white-space: nowrap;
  color: var(--yellow);
  animation: leaveleft 75s linear forwards;
  scale: 1;
}

@keyframes leaveleft {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-200vw);
  }
}
